export class CertProgramAreaDTO {
    id: number;
    certAreaId: number;
    certAttemptTypeId: number;
    certAttemptType: string;
    comment?: any;
    grade?: any;
    granted?: any;
    recommendedUser?: any;
    recommendedDate?: any;
    createUser: string;
    createDate: Date;
    modUser: string;
    modDate: Date;
    certProgramId: number;
    certStatusId: number;
    recommendationId?: any;
    applicationDate: Date;
    beginDate: Date;
    expirationDate: Date;
    completedDate: Date;
    certAreaTypeId: number;
    certAreaType: string;
    salesforceId: string;
    certStatus: string;
    areaName: string;
    certName: string;
    apply: boolean;
}

export class CertProgram {
    id: number;
    profileId: number;
    certTypeId: number;
    scheduledDate: Date;
    completedDate: Date;
    trackStartDate: Date;
    recommendDate: Date;
    applicationDate: Date;
    createUser: string;
    createDate: Date;
    modUser: string;
    modDate: Date;
    salesforceId: string;
    certStatusId?: number;
    recStatusId?: any;
    certType: string;
    certStatus?: any;
    fsRec: string;
    principalRec: string;
    certProgramAreaDTOs: CertProgramAreaDTO[];
    fsCertRecommendationDTOs: CertRecommendationDTO[];
    principalCertRecommendationDTOs: CertRecommendationDTO[];
    certRecommendation: CertRecommendationDTO[]; 
    isValidOrRecommendedPermanentCert: boolean;
}

export class CertProgramUpdateCommand {
    constructor() {
        this.certProgram = null;
        this.certRecommendations = [];
    }
    certProgram: CertProgram;
    certRecommendations: CertRecommendationDTO[];
}

export class CertRecommendationDTO {
    id: number;
    certProgramId: number;
    // coachOrPrincipalId: number;
    coachId: number;
    principalId: number;
    recommendationId: number;
    recDescription: string;
    IsPrincipal: boolean;
    mopDate: string;
    createDate: Date;
}

export enum CertStatus {
    Revoked = 1,
    PDU_Review = 2,
    Expired = 3,
    Inactive = 4,
    PendingSuspension = 5,
    VoluntarySurrender = 6,
    NotApplied = 7,
    Suspended = 8,
    Invalid = 9,
    Valid = 10,
    Canceled = 11,
    Applied = 12,
    Recommended = 13,
    Denied = 14,
    Active = 15
}

//export class PrincipalCertRecommendationDTO {
//    id: number;
//    principalId: number;
//    recommendationId: number;
//}

