import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, retry, shareReplay } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';
import { CandidateContract, CandidateContractDetailDTO } from '../shared/models/candidateContract.model';
import { CertAreaDTO } from '../shared/models/certArea.model';
import { CertAreaAddCommand } from '../shared/models/certAreaAddCommand';
import { CertProgram, CertProgramUpdateCommand } from '../shared/models/certProgram.model';
import { Employment } from '../shared/models/employment.model';
import { FinancialStatusUpdate } from '../shared/models/financial-status-update';
import { Result } from '../shared/models/result.model';
import { CertType } from './../shared/models/certType.model';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})

export class ContractService {
    headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    apiUrl = '';
    constructor(private httpclient: HttpClient, private configSvc: ConfigService, private authService: AuthService) {
        this.apiUrl = this.configSvc.getApiUrl();
    }

    // Return the default Withdrawal Method for the given profileIDs employment.
    public GetWithDrawalMethodByCandidateID(candidateID: number) {
        return this.httpclient.get<any>(`${this.apiUrl}/financial/getWithDrawalMethod?profileID=${candidateID}`).pipe(
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public GetWithdrawalMethodTypes() {
        return this.httpclient.get<any>(`${this.apiUrl}/financial/GetWithdrawalMethodTypes`).pipe(
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public GetInternInformationByCandidateID(candidateID: number) {
        return this.httpclient
            .get<any>(`${this.apiUrl}/Intern/Candidate/${candidateID}`).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public GetDownPaymentsByCandidateID(candidateID: number) {
        return this.httpclient.get<any>(`${this.apiUrl}/financial/getDownPaymentsByCandidateId?candidateID=${candidateID}`).pipe(
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public GetProfileID(candidateID: string) {
        return this.httpclient.get<any>(`${this.apiUrl}/GetProfileId?CandidateId=${candidateID}`).pipe(
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public GetProfileIDFromCandidateId(candidateID: string) {
        return this.httpclient.get<any>(`${this.apiUrl}/GetProfileIdFromCandidateId?CandidateId=${candidateID}`).pipe(
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public AddDownPayment(contract: CandidateContract) {
        let headersToPass = new HttpHeaders();

        headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');

        return this.httpclient.post<CandidateContract>

            (`${this.apiUrl}/financial/AddCandidateContract`, contract, { headers: headersToPass }).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public AddEnrollment(contract: CandidateContract[], isSponsoredContract: boolean) {

        const enrollmentData = {
          Contract: contract,
          IsSponsoredContract: isSponsoredContract
        };
        let headersToPass = new HttpHeaders();

        headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');

        return this.httpclient.post<Result>
            (`${this.apiUrl}/v1.0/CandidateContract/Enrollment/`, enrollmentData, { headers: headersToPass });
    }

    public AddEnrollmentFeeDiscount(profileid: number, contractId: number, candidateContractDetail: CandidateContractDetailDTO) {
        let headersToPass = new HttpHeaders();
        headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');

        return this.httpclient.patch<Result>
            (`${this.apiUrl}/v1.0/profile/${profileid}/contract/${contractId}`, candidateContractDetail, { headers: headersToPass }).pipe(
                catchError(error => {
                    return throwError(error);
                })
            );
    }

    public UpdateEmployment(employment: Employment, hasCampusChanged: boolean) {
        let campusChangeParam = hasCampusChanged ? '?campuschanged=true' : '';
        let headersToPass = new HttpHeaders();
        headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');
        return this.httpclient.put<any>
            (`${this.apiUrl}/employment` + campusChangeParam, employment, { headers: headersToPass }).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public ReviseEmployment(employment: Employment) {
        let headersToPass = new HttpHeaders();
        headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');
        return this.httpclient.put<any>
            (`${this.apiUrl}/v1.0/employment/`, employment, { headers: headersToPass }).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public AddEmployment(employment: Employment) {
        let headersToPass = new HttpHeaders();
        headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');
        return this.httpclient.post<any>
            (`${this.apiUrl}/employment`, employment, { headers: headersToPass }).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public UpdateCandidateStatus(candidateID: string, candidatestatusId: number) {
        return this.httpclient.get<number>
            (`${this.apiUrl}/UpdateCandidateStatusId?candidateId=${candidateID}&candidatestatusId=${candidatestatusId}`).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public UpdateFinancialStatus(profileId: number, financialStatusID: number, date: Date | null, sendUpdateTo2CP: boolean | null, candidateStatusID: number | null = null) {
        const data = new FinancialStatusUpdate();
        data.profileID = profileId;
        data.financialStatusID = financialStatusID;
        data.date = date;
        data.sendUpdateTo2CP = sendUpdateTo2CP;
        data.modUser = this.authService.user.displayName;
        data.candidateStatusID = candidateStatusID;

        return this
            .httpclient
            .put<Result>(`${this.apiUrl}/profile/financialstatus/${profileId}`, data).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public GetExistingDownPaymentsByCandidateId(candidateID: number) {
        return this.httpclient.get<any>
            (`${this.apiUrl}/financial/GetExistingDownPaymentsByCandidateId?candidateID=${candidateID}`).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public GetExistingContractsByProfileId(profileId: number) {
        return this.httpclient.get<any>
            (`${this.apiUrl}/v1.0/Contracts/Profile/${profileId}`).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public GetLatestEmploymentByCandidateId(candidateID: string) {
        return this.httpclient.get<any>
            (`${this.apiUrl}/GetLatestEmploymentByCandidateID?candidateId=${candidateID}`).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public GetCampusesByStateId(stateID: number) {
        return this.httpclient.get<any>
            (`${this.apiUrl}/campus/${stateID}`).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public GetGradesFromTable() {
        return this.httpclient.get<any>
            (`${this.apiUrl}/GetGradesFromTable`).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public isSoeRequired(campusID: number) {
        return this.httpclient.get<any>
            (`${this.apiUrl}/isSoeRequired?campusID=${campusID}`).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public GetCertProgramsbyProfileId(profileID: number) {
        return this.httpclient.get<any>
            (`${this.apiUrl}/CandidateCertification/GetCertPrograms?profileID=${profileID}`).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public GetCertTypes(stateID: number|null = null) {
      if (stateID) {
        return this.httpclient.get<any>
          (`${this.apiUrl}/CandidateCertification/GetCertTypes?stateId=` + stateID).pipe(
              catchError(error => {
                  console.log(error);
                  return EMPTY;
              }),
              shareReplay()
          );
      }
      return this.httpclient.get<any>
        (`${this.apiUrl}/CandidateCertification/GetCertTypes`).pipe(
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public GetCertAreas(stateID: number) {
        return this.httpclient.get<any>
            (`${this.apiUrl}/CandidateCertification/GetCertAreas?stateID=` + stateID).pipe(// need to change this to work with any given stateID
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public GetSmkCertAreas(profileId: number) {
        return this.httpclient.get<CertAreaDTO[]>
            (`${this.apiUrl}/CandidateCertification/GetSmkCertAreas/${profileId}`).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public AddCertPrograms(certProgram: CertProgram) {
        let headersToPass = new HttpHeaders();
        headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');
        return this.httpclient.post<any>
            (`${this.apiUrl}/CandidateCertification/AddCertPrograms`, certProgram, { headers: headersToPass }).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public GetContractByProfileId(profileID: number, campusID: string) {
        return this.httpclient.get<any>
            (`${this.apiUrl}/financial/getContractByProfileId?profileID=${profileID} &campusID=${campusID}`).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public UpdateCertPrograms(certProgram: CertProgramUpdateCommand) {
        let headersToPass = new HttpHeaders();
        headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');
        return this.httpclient.put<any>
            (`${this.apiUrl}/CandidateCertification/UpdateCertificate`, certProgram, { headers: headersToPass }).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public GetCertStatuses() {
        return this.httpclient.get<any>
            (`${this.apiUrl}/CandidateCertification/GetCertStatus`).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public GetRecommendationStatuses() {
        return this.httpclient.get<any>
            (`${this.apiUrl}/CandidateCertification/GetRecommendationStatus`).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public GetRecommenders(isFieldSupervisor: boolean, profileId: number) {
        return this.httpclient.get<any>
            (`${this.apiUrl}/CandidateCertification/GetRecommenders?internId=${isFieldSupervisor}&profileId=${profileId}`).pipe(
                retry(0),
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public GetActiveApprovedEmployment(candidateId: string) {
        return this.httpclient.get<any>
            (`${this.apiUrl}/GetActiveApprovedEmployment?candidateId = ${candidateId}`).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public GetAllEmployment(profileId: number) {
        return this.httpclient.get<any>
            (`${this.apiUrl}/employment/${profileId}`).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public GetCertProgram(id: number) {
        return this.httpclient.get<any>(`${this.apiUrl}/CandidateCertification/GetCertProgram?id=${id}`).pipe(
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public addCertAreaForCertProgram(command: CertAreaAddCommand) {
        let headersToPass = new HttpHeaders();
        headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');
        return this.httpclient.post<any>(`${this.apiUrl}/financial/AddCertAreaForCertProgram`, command, { headers: headersToPass }).pipe(
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public removeCertAreaForCertProgram(certProgramAreaId: number) {
        return this.httpclient.delete<any>(`${this.apiUrl}/CandidateCertification/RemoveCertAreaFromCertProgram/${certProgramAreaId}`).pipe(
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public getCertAreasofCertProgram(CertProgramId: number) {
        return this.httpclient.get<any>(`${this.apiUrl}/CandidateCertification/GetCertAreasofCertProgram?certProgramId=${CertProgramId}`).pipe(
            catchError(error => {
                console.log(error);
                return EMPTY;
            }),
            shareReplay()
        );
    }

    public SendContract(contract: CandidateContract) {
        let headersToPass = new HttpHeaders();
        headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');
        return this.httpclient.post<CandidateContract>
            (`${this.apiUrl}/v1.0/Contract?submit=true`, contract, { headers: headersToPass }).pipe(
                shareReplay()
            );
    }

    public SaveContract(contract: CandidateContract, isThirdParty = false) {
        let thirdPartyParameter = '';
        if (isThirdParty) {
            thirdPartyParameter = '?thirdParty'
        }
        let headersToPass = new HttpHeaders();
        headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');
        return this.httpclient.post<CandidateContract>
            (`${this.apiUrl}/v1.0/Contract${thirdPartyParameter}`, contract, { headers: headersToPass }).pipe(
                catchError(error => {
                    console.log(error);
                    throw error;
                }),
                shareReplay()
            );
    }

    public ReSubmitContract(contractId: number) {
        let headersToPass = new HttpHeaders();
        headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');
        return this.httpclient.put<any>
            (`${this.apiUrl}/v1.0/Contract`, contractId, { headers: headersToPass }).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public GetCurrentContract(profileId: number) {
        return this.httpclient.get<any>(`${this.apiUrl}/financial/currentcontract/${profileId}`);
    }

    public GetCertTypesByProfileState(stateId: number) {
        return this.httpclient.get<CertType[]>
            (`${this.apiUrl}/CandidateCertification/GetCertTypesByProfileState/${stateId}`).pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                }),
                shareReplay()
            );
    }

    public GetMontlyPaymentContractSubscriptionPayment(profileId: number) {
        return this.httpclient.get<any>(`${this.apiUrl}/v1.0/contracts/monthly-payment/subscription-payment/${profileId}`);
    }

    public CancelMonthlySubscriptionContract(candidateContractId: number, cancelationDate: Date) {
        let headersToPass = new HttpHeaders();
        headersToPass = headersToPass.set('Content-Type', 'application/json; charset=utf-8');
        const bodyToPass = { cancelationDate: cancelationDate };
        return this.httpclient.delete<any>(`${this.apiUrl}/v1.0/contracts/monthly/${candidateContractId}`, { headers: headersToPass, body: bodyToPass }).pipe(
            catchError(error => {
                console.error(error);
                return EMPTY;
            })
        );
    }
}
